import React, { useState } from 'react';
import Section from '@components/Section';
import Headings from '@components/Headings';
import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

const Subscription: React.FunctionComponent<{}> = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    if (email.trim()) {
      try {
        const response = await fetch('https://api.buttondown.email/v1/subscribers', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token a2b17c12-7258-4958-b1ef-2ddafd2e585c',
          },
          body: JSON.stringify({ email }),
        });
        const responseBody = await response.json();

        if (responseBody.creation_date) {
          setSubscribed(true);
          setEmail('');
        } else {
          setError('Something went wrong! Please try again.');
        }
      } catch (err) {
        console.log({ err });
        setError('Something went wrong! Please try again.');
      }
    } else {
      setError('Please enter valid email address');
    }
    setSubmitting(false);
  };

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.currentTarget.value);
    setError('');
  }

  return (
    <Section narrow>
      <SubscriptionContainer>
        <Content>
          <Heading>Join the Newsletter</Heading>
          <Text>Subscribe to get my latest write-ups by email</Text>
          <Text>No spam, pinky promise!</Text>
        </Content>
        <Content>
          <Form onSubmit={handleSubmit} hasError={error}>
            <Input
              placeholder="your@email.com"
              name="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              hasError={error}
            />
            <Button
              type="submit"
              hasError={error}
              subscribed={subscribed}
              disabled={submitting || subscribed}
            >
              {subscribed ? <CheckMarkIcon /> : 'Subscribe'}
            </Button>
            {error && <Error dangerouslySetInnerHTML={{ __html: error }} />}
          </Form>
        </Content>
      </SubscriptionContainer>
    </Section>
  );
};

export default Subscription;

const SubscriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 64px 55px 55px 55px;
  margin: 10px 100px 100px 100px;
  background: ${(p) => p.theme.colors.card};
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  z-index: 1;

  ${mediaqueries.tablet`
    padding: 50px 0 0;
    text-align: center;
    flex-direction: column;
  `}

  ${mediaqueries.phablet`
    margin: -20px auto 80px;
    flex-direction: column;
  `}
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 640px;

  ${mediaqueries.tablet`
    h3 {
      padding: 0 50px;
    }
  `}

  ${mediaqueries.phone`
    h3 {
      padding: 0 24px;
    }
  `}
`;

const Heading = styled(Headings.h3)`
  margin-bottom: 20px;

  ${mediaqueries.tablet`
    margin-bottom: 15px;
  `}
`;

const Text = styled.p`
  margin: 0 auto 30px;
  color: ${(p) => p.theme.colors.grey};
  line-height: 1.75;

  ${mediaqueries.tablet`
    padding: 0 26px;
    margin: 0 auto 25px;
  `}
`;

const Form = styled.form<{ hasError: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input<{ hasError: string }>`
  position: relative;
  background: ${(p) =>
    p.hasError ? p.theme.colors.errorBackground : p.theme.colors.inputBackground};
  border-radius: 4px;
  border: none;
  padding: 13px 21px;
  width: 400px;
  color: ${(p) => p.theme.colors.primary};

  ::placeholder {
    color: ${(p) => p.theme.colors.track};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${(p) => p.theme.colors.track};
  }

  ::-ms-input-placeholder {
    color: ${(p) => p.theme.colors.track};
  }

  ${mediaqueries.tablet`
    width: calc(100% - 36px);
    margin: 0 18px;
    padding: 14px 14px 14px 30px;
    margin-bottom: 30px;
  `}
`;

const Button = styled.button<{ hasError: string; subscribed: boolean }>`
  position: relative;
  display: flex;
  margin: 30px 0 0 0;
  align-items: center;
  justify-content: center;
  width: 161px;
  height: 42px;
  border: 1px solid ${(p) => p.theme.colors.accent};
  color: ${(p) => p.theme.colors.background};
  background: ${(p) => (p.subscribed ? 'transparent' : p.theme.colors.accent)};
  opacity: ${(p) => (p.disabled ? '0.5' : '')};
  font-weight: 600;
  border-radius: 6px;
  letter-spacing: 0.42px;
  transition: border-color 0.2s var(--ease-in-out-quad), background 0.2s var(--ease-in-out-quad),
    color 0.2s var(--ease-in-out-quad);

  &[disabled] {
    cursor: not-allowed;
  }

  svg * {
    fill: ${(p) => p.theme.colors.accent};
  }

  ${mediaqueries.tablet`
    width: calc(100% - 36px);
    margin: 0 18px;
    margin-bottom: 30px;
  `}
`;

const Error = styled.div`
  position: absolute;
  top: 46px;
  // left: 35px;
  // bottom: -20px;
  color: ${(p) => p.theme.colors.error};
  font-size: 12px;

  a {
    color: ${(p) => p.theme.colors.error};
    text-decoration: underline;
  }

  ${mediaqueries.tablet`
    left: 50px;
    top: 50px;
  `}
`;

const CheckMarkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
      fill="#08080B"
    />
  </svg>
);
